/*!
 *   Pagina de inicio de LPB v1.0.0
 *   Author: Strootman Development
!*/


/*  CORE IMPORT
========================== */
@import 'core';


body {
  background-color: #edeff4;
  font-family: $font-montserrat !important;
}

*:focus{
  outline: none;
}

.tooltip {
  min-width: 100px;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  .tooltip-inner {
    background: #FFF;
  }
  .tooltip-arrow {
    border-bottom-color: #FFF;
  }
}

.dropzone {
  border: 1px solid #e6e6e6;
}

.form-control {
  font-size: 12px;
}
.enfasis {
  font-size: 110%;
  text-transform: uppercase;
}

a {
  -webkit-transition-duration: 250ms;
  -webkit-transition-property: color, background-color, opacity, border;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-duration: 250ms;
  -moz-transition-property: color, background-color, opacity, border;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-duration: 250ms;
  -o-transition-property: color, background-color, opacity, border;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 250ms;
  transition-property: color, background-color, opacity, border;
  transition-timing-function: ease-in-out;
  text-shadow: none;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  &:hover {
    -webkit-transition-duration: 250ms;
    -webkit-transition-property: color, background-color, opacity, border;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-duration: 250ms;
    -moz-transition-property: color, background-color, opacity, border;
    -moz-transition-timing-function: ease-in-out;
    -o-transition-duration: 250ms;
    -o-transition-property: color, background-color, opacity, border;
    -o-transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    transition-property: color, background-color, opacity, border;
    transition-timing-function: ease-in-out;
  }
}

.post:not(.news-post) #goTop {
  width: 40px;
  height: 40px;
  line-height: 50px;
  display: inline-block;
  background: rgba(50, 50, 50, 0.5);
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  text-align: center;
  -webkit-transition-duration: 250ms !important;
  -webkit-transition-property: background-color !important;
  -webkit-transition-timing-function: ease-in-out !important;
  -moz-transition-duration: 250ms !important;
  -moz-transition-property: background-color !important;
  -moz-transition-timing-function: ease-in-out !important;
  -o-transition-duration: 250ms !important;
  -o-transition-property: background-color !important;
  -o-transition-timing-function: ease-in-out !important;
  transition-duration: 250ms !important;
  transition-property: background-color !important;
  transition-timing-function: ease-in-out !important;
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}

.modal-open {
  padding-right: 0 !important;
  overflow-y: scroll;
  .modal{
    padding-top: 4%;
    &-backdrop{
      background-color: #202020;
      &.in{
        // You can change this to any alpha
        opacity: .95;
      }
    }
    &-content {
      position: relative;
      background-color: transparent;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: none;
      border-radius: 0;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #FFF;
      .modal-header {
        border: none;
        .modal-title {
          font-weight: 700;
        }
        .close {
          color: #FFF;
          text-shadow: 0 1px 0 #000;
          filter: alpha(opacity=80);
          opacity: .8;
        }
      }
      .video-container {
        height: 620px;
        .mejs__container {
          max-width: 100%;
          height: 100% !important;
          .mejs__controls {
            background: none !important;
          }
        }
        .fb-video,
        .fb-video .mejs__overlay {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}

section {
  padding: 60px 0;
  position: relative;
  &.inverse {
    background-color: #FFF;
    .container {
      .section-header {
        @media screen and (max-width : 767px) {
          padding: 0 20px;
        }
      }
      .section-header > h2, * {
        color: $clr-darkDK;
      }
    }
  }
  .container {
    position: relative;
    .section-header {
      h2 {
        font-size: 28px;
        color: white;
        text-transform: uppercase;
        margin: 24px 0 54px 0;
      }
    }
  }
}

.owl-carousel {
	position: relative;
	height: 100%;
  .owl-next, .owl-prev {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    @media screen and (max-width : 767px) {
      display: none !important;
    }
    i {
      color: #ffffff;
      text-shadow: 0px 0px 2px rgba(0,0,0, 1);
      font-size: 40px;
    }
  }
  .owl-prev {
    left: 0px;
  }
  .owl-next {
    right: 0px;
  }
}
.owl-thumbs {
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 3rem 0;
  @media screen and (max-width : 767px) {
    display: none;
  }
  .owl-thumb-item {
    border: none;
    background: transparent;
    width: 33%;
    display: inline-block;
    opacity: 0.3;
    .title {
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      color: #FFF;
      display: block;
    }
    .author {
      font-family: $font-montserrat;
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      white-space: nowrap;
      display: -webkit-inline-box;
      display: inline-flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      border-radius: .25rem;
      box-shadow: 0 0 12px rgba(0,0,0,.3);
      background-color: $clr-yellow;
      margin-bottom: 1rem;
      padding: .25rem .75rem;
    }
    .date {
      font-size: 10px;
      line-height: 1.2em;
      vertical-align: middle;
      text-transform: uppercase;
      color: rgb(154, 157, 162);
      display: block;
      margin-top: 7px;
    }
    &.active {
      opacity: 1;
    }
  }
}


/*  BACKGROUNDS
========================== */
.no-bg {
  background: transparent;
}
.bg-primary {
  background-color: $clr-primary;
  &.lt { background-color: $clr-primaryLT }
  &.dk { background-color: $clr-primaryDK }
}
.bg-success {
  background-color: $clr-green;
  &.lt { background-color: $clr-greenLT }
  &.dk { background-color: $clr-greenDK }
}
.bg-warning {
  background-color: $clr-warning;
  &.lt { background-color: $clr-warningLT }
  &.dk { background-color: $clr-warningDK }
}
.bg-danger {
  background-color: $clr-danger;
  &.lt { background-color: $clr-dangerLT }
  &.dk { background-color: $clr-dangerDK }
}
.bg-info {
  background-color: $clr-mint;
  &.lt { background-color: $clr-mintLT }
  &.dk { background-color: $clr-mintDK }
}
.bg-gray {
  background-color: #F0F0F0;
  &.lt { background-color: #FFF }
  &.dk { background-color: #E8E8E8 }
}
.bg-dark {
  background-color: #202020;
  &.lt { background-color: #3a3a3a }
  &.dk { background-color: #1a1a1a }
}


/*  BUTTONS
========================== */
.btn {
  &.btn-primary {
    background: $clr-primary;
    border-color: $clr-primary;
    &.btn-outline {
    background: transparent;
    border-radius: 0;
    }
  }
}
.btn-group {
  .btn {
    &.btn-sm {
      padding: 4px 10px 2px;
    }
    background: $clr-greyLT;
    &.btn-primary {
      background: $clr-primary;
      border-color: $clr-primary;
      &:hover {
        background: $clr-primaryDK;
        border-color: $clr-primaryDK;
      }
    }
    &.btn-success {
      background: $clr-green;
      border-color: $clr-green;
      &:hover {
        background: $clr-greenDK;
        border-color: $clr-greenDK;
      }
    }
    &.btn-warning {
      background: $clr-warning;
      border-color: $clr-warning;
      &:hover {
        background: $clr-warningDK;
        border-color: $clr-warningDK;
      }
    }
    &.btn-danger {
      background: $clr-danger;
      border-color: $clr-danger;
      &:hover {
        background: $clr-dangerDK;
        border-color: $clr-dangerDK;
      }
    }
  }
}

.toggle-vis {
  padding: 10px 12px 9px !important;
}


/*  LOGIN
========================== */
.login-register {
  .login-sidebar {
    background-color: $clr-primary;
    .logo {
      background-image: url(../img/logo.jpg);
      height: 85px;
      margin: 0;
    }
  }
  .login {
    width: 240px;
    height: 464px;
    border-top: 2px solid $clr-primary;
    margin: auto;
    padding: 40px;
    background-color: transparentize($clr-darkDK, .1);
    position: absolute;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: 50%;
    margin-top: -273px;
    left: 50%;
    margin-left: -160px;
    .logo {
      width:100%;
      height: 180px;
      margin-bottom: 20px;
      background-image: url(../img/logo.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .validation {
      position:absolute;
      z-index:1;
      right:10px;
      top:6px;
      opacity:0;
    }
    .disclaimer{
      position: absolute;
      bottom: 20px;
      left: 35px;
      width: 250px;
      color: #5B5E6F;
      font-size: 10px;
      text-align: left;
    }
    &_title {
      color: #fff;
      height: 60px;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: -0.02em;
    }
    &_fields {
      width: 100%;
      height: 215px;
      position: absolute;
      left: 0;
      i {
        position: absolute;
        z-index: 1;
        left: 36px;
        top: 8px;
        opacity: .5;
        font-size: 18px;
        width: 18px;
        text-align: center;
      }
      input[type='password'] {
        color:#DC6180 !important;
      }
      input[type='text'], input[type='password'] {
        color: #afb1be;
        //width: 190px;
        width: 100%;
        margin-top:-2px;
        //background: rgb(50, 54, 74);
        background-color: $clr-darkDK;
        left: 0;
        padding: 10px 65px;
        border-top: 2px solid lighten($clr-darkDK, 5%);
        border-bottom: 2px solid lighten($clr-darkDK, 5%);
        border-right: none;
        border-left: none;
        outline: none;
        box-shadow: none;
      }
      &__user, &__password, &__remember {
        position:relative;
      }
      &__remember {
        left: 0;
        width: 80%;
        right: 0;
        margin: 5px auto 0;
        color: #FFF;
        font-size: 13px;
        .checkbox {
          padding-bottom: 7px;
        }
      }
      &__submit {
        position: relative;
        top: 5px;
        left: 0;
        width: 80%;
        right: 0;
        margin: auto;
        .forgot{
          float: right;
          font-size: 10px;
          margin-top: 11px;
          text-decoration: underline;
          a{
            color: $clr-primary;
          }
        }
        input {
          text-transform: uppercase;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          padding: 13px 30px;
          font-size: 11px;
          line-height: 1.5em;
          border-radius: 2px;
          transition-property: background, color;
          transition-duration:.2s;
          &:focus {
            box-shadow:none;
            outline:none;
          }
          &:hover {
            color: white;
            background: $clr-primaryDK;
            cursor: pointer;
            transition-property:background, color;
            transition-duration: .2s;
          }
        }
      }
    }
  }
  #recoverform {
    display: none;
  }
}

/*  LAYOUT
========================== */
.lbpmexico {
header {
  margin: 0px;
  padding: 0px;
  z-index: 999;
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 120px;
  background-color: $clr-carbonDK;
  .user-nav {
    display: block;
    text-align: right;
    background-color: $clr-carbonDK;
    .user-nav-inner {
      ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          float: right;
          font-size: 9px;
          line-height: 1.5em;
          text-transform: uppercase;
          font-weight: 700;
          &:not(.donate)::before {
            content: "\2F";
            display: inline-block;
            color: #6b6d70;
            margin: 0 16px 0 14px;
          }
          & > a {
            color: #fff;
            transition: all 0.3s ease;
            display: inline-block;
            height: 48px;
            line-height: 48px;
            @media screen and (max-width : 767px) {
              height: 36px;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
  .main-nav {
    background-color: $clr-carbonDK;
    position: relative;
    margin-bottom: 0;
    border: none;
    .main-nav-inner {
      display: none;
    }
    /*.main-nav-inner {
      display: none;
      position: relative;
      z-index: 1;
      background-color: $clr-carbon;
      &::before {
        content: "";
        position: absolute;
        display: none;
        top: 0;
        bottom: 0;
        width: 210px;
        background-color: $clr-carbonDK;
        left: -25px;
        margin-left: 0;
        transform: skew(-25deg);
        @media (min-width: 768px) {
          display: block;
        }
      }
      .header-logo {
        position: absolute;
        z-index: 3;
        bottom: -15px;
        left: 0;
        display: block;
        width: 150px;
        img {
          max-width: 100%;
          height: auto;
          position: relative;
        }
      }
      .main-navigation {
        text-align: right;
        ul {
          display: inline-block;
          text-align: left;
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            text-align: center;
            display: inline-block;
            padding: 0;
            a {
              display: block;
              position: relative;
              color: #fff;
              padding: 0 50px;
              line-height: 62px;
              text-decoration: none;
              position: relative;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              transition: color 0.3s ease;
              transition: background-color 0.3s ease;
            }
            &.active > a, &:hover > a {
              background-color: $clr-primary;
            }
            &.has-children {
              & > a::after{
                content: "";
                display: inline-block;
                margin-left: 9px;
                width: 6px;
                height: 4px;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
                background-size: 6px 4px;
                background-repeat: no-repeat;
                background-position: center center;
                top: -2px;
                position: relative;
              }
            }
            & > .main-nav-sub,
            & > .main-nav-sub-2,
            & > .main-nav-sub-3{
              transform-style: preserve-3d;
              transform: rotateX(-75deg);
              visibility: hidden;
            }
            &:hover > .main-nav-sub,
            &:hover > .main-nav-sub-2,
            &:hover > .main-nav-sub-3{
              transform: rotateX(0deg);
              opacity: 1;
              visibility: visible;
            }
            &:hover {
              .main-nav-sub{
                display: block;
              }
            }
            .main-nav-sub {
              position: absolute;
              top: 62px;
              z-index: 1000;
              margin: 0;
              padding: 0;
              min-width: 175px;
              background-color: #1e2024;
              border: solid 1px #292c31;
              opacity: 0;
              transform-origin: 0% 0%;
              transition: transform 0.3s, opacity 0.3s;
              li {
                position: relative;
                margin: 0;
                padding: 0;
                display: block;
                a {
                  text-decoration: none;
                  display: block;
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 11px;
                  color: #fff;
                  text-align: left;
                  line-height: 1.5em;
                  padding: 16px 30px;
                  letter-spacing: normal;
                  border-bottom: 1px solid #292c31;
                  transition: background-color 0.3s ease;
                  &:hover {
                    color: $clr-warning;
                    background-color:transparent;
                  }
                }
                &.has-children {
                  & > a::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    margin-top: -2px;
                    width: 6px;
                    height: 4px;
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23ffffff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
                    background-size: 6px 4px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    transform:rotate(-90deg);
                  }
                }
                &:hover {
                  .main-nav-sub-2{
                    display: block;
                    opacity: 1;
                    list-style: none;
                    padding: 0;
                    background-color:#1e2024;
                  }
                }
                &:last-child > a {
                  border-bottom: none;
                }
                .main-nav-sub-2 {
                  min-width: 175px;
                  border: solid 1px #292c31;
                  position: absolute;
                  left: 100%;
                  top: -1px;
                  margin: 0;
                  padding: 0;
                  opacity: 0;
                  transform-origin: 0% 0%;
                  transition: transform 0.4s, opacity 0.4s;
                  li:hover .main-nav-sub-3 {
                    opacity: 1;
                    list-style: none;
                    padding: 0;
                    border: solid 1px #292c31;
                    background-color: #1e2024;
                  }
                  .main-nav-sub-3{
                    min-width: 175px;
                    position: absolute;
                    left: 100%;
                    top: 0;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    transform-origin: 0% 0%;
                    transition: transform 0.4s, opacity 0.4s;
                  }
                }
              }
            }
          }
        }
      }
    }*/
    .navbar-header {
      .navbar-brand {
        position: absolute;
        z-index: 3;
        top: -45px;
        left: 0;
        display: block;
        width: 150px;
        padding: 0 !important;
        @media screen and (max-width : 767px) {
          width: 120px;
          top: -30px;
          left: auto;
          padding: 0 18px !important;
        }
        img {
          width: 100%;
        }
      }
    }
    .navbar-collapse {
      background-color: $clr-carbon;
      .navbar-right {
        li {
          text-align: center;
          display: inline-block;
          padding: 0;
          a {
            display: block;
            position: relative;
            color: #fff;
            padding: 0 50px;
            line-height: 62px;
            text-decoration: none;
            position: relative;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            transition: color 0.3s ease;
            transition: background-color 0.3s ease;
          }
          &.active > a, &:hover > a {
            background-color: $clr-primary;
          }
          &.has-children {
            & > a::after{
              content: "";
              display: inline-block;
              margin-left: 9px;
              width: 6px;
              height: 4px;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
              background-size: 6px 4px;
              background-repeat: no-repeat;
              background-position: center center;
              top: -2px;
              position: relative;
            }
          }
          & > .main-nav-sub,
          & > .main-nav-sub-2,
          & > .main-nav-sub-3{
            transform-style: preserve-3d;
            transform: rotateX(-75deg);
            visibility: hidden;
          }
          &:hover > .main-nav-sub,
          &:hover > .main-nav-sub-2,
          &:hover > .main-nav-sub-3{
            transform: rotateX(0deg);
            opacity: 1;
            visibility: visible;
          }
          &:hover {
            .main-nav-sub{
              display: block;
            }
          }
          .main-nav-sub {
            position: absolute;
            top: 62px;
            z-index: 1000;
            margin: 0;
            padding: 0;
            min-width: 175px;
            background-color: #1e2024;
            border: solid 1px #292c31;
            opacity: 0;
            transform-origin: 0% 0%;
            transition: transform 0.3s, opacity 0.3s;
            li {
              position: relative;
              margin: 0;
              padding: 0;
              display: block;
              a {
                text-decoration: none;
                display: block;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 11px;
                color: #fff;
                text-align: left;
                line-height: 1.5em;
                padding: 16px 30px;
                letter-spacing: normal;
                border-bottom: 1px solid #292c31;
                transition: background-color 0.3s ease;
                &:hover {
                  color: $clr-warning;
                  background-color:transparent;
                }
              }
              &.has-children {
                & > a::after {
                  content: "";
                  display: block;
                  position: absolute;
                  right: 24px;
                  top: 50%;
                  margin-top: -2px;
                  width: 6px;
                  height: 4px;
                  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23ffffff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
                  background-size: 6px 4px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  transform:rotate(-90deg);
                }
              }
              &:hover {
                .main-nav-sub-2{
                  display: block;
                  opacity: 1;
                  list-style: none;
                  padding: 0;
                  background-color:#1e2024;
                }
              }
              &:last-child > a {
                border-bottom: none;
              }
              .main-nav-sub-2 {
                min-width: 175px;
                border: solid 1px #292c31;
                position: absolute;
                left: 100%;
                top: -1px;
                margin: 0;
                padding: 0;
                opacity: 0;
                transform-origin: 0% 0%;
                transition: transform 0.4s, opacity 0.4s;
                li:hover .main-nav-sub-3 {
                  opacity: 1;
                  list-style: none;
                  padding: 0;
                  border: solid 1px #292c31;
                  background-color: #1e2024;
                }
                .main-nav-sub-3{
                  min-width: 175px;
                  position: absolute;
                  left: 100%;
                  top: 0;
                  margin: 0;
                  padding: 0;
                  opacity: 0;
                  transform-origin: 0% 0%;
                  transition: transform 0.4s, opacity 0.4s;
                }
              }
            }
          }
        }
        &::before {
          content: "";
          position: absolute;
          display: none;
          top: 0;
          bottom: 0;
          width: 210px;
          background-color: $clr-carbonDK;
          left: -25px;
          margin-left: 0;
          transform: skew(-25deg);
          @media (min-width: 768px) {
            display: block;
          }
        }
      }
    }
  }
  /*#logo {
    margin: 0;
    padding: 0px;
    border: none;
    width: 135px;
    img {
      width: 135px;
      height: auto;
      margin: 20px 0 -71px;
      max-width: 100%;
      opacity: 1 !important;
    }
  }
  .site-navigation {
    position: relative;
    .user-menu {
      ul {
        text-align: right;
      }
    }
    .menu-container {
      background-color: $clr-dark;
      width: 75%;
      position: absolute;
      right: 0;
      ul {
        -webkit-font-smoothing: subpixel-antialiased;
        text-align: right;
        li {
          display: inline-block;
          white-space: nowrap;
          color: #FFF;
          &.donate {
            //color: $clr-primary;
          }
          a {
            display: block;
            position: relative;
            padding: 10px;
            font-weight: 600;
            font-size: 12px;
            // font-family: $font-poppins;
            margin-left: 18px;
            margin-right: 18px;
            line-height: 1;
            margin-bottom: 26px;
            text-transform: uppercase;
            letter-spacing: 1px;
            overflow: hidden;
            &:before {
              box-sizing: border-box;
              transform: translateX(100%);
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 99.5%;
              height: 2px;
              border-bottom: 2px solid transparent;
              border-left: 2px solid transparent;
            }
            &:after {
              box-sizing: border-box;
              transform: translateX(-100%);
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 99.5%;
              height: 2px;
              border-top: 2px solid transparent;
              border-right: 2px solid transparent;
            }
      			&:hover {
      				color: inherit;
      				text-decoration: none;
              &:before {
                transition: .1s transform linear, .1s height linear .1s;
                transform: translateX(0);
                height: 100%;
                border-color: #FFF;
              }
              &:after {
                transition: .1s transform linear .2s, .1s height linear .3s;
                transform: translateX(0);
                height: 100%;
                border-color: #FFF;
              }
      			}
          }
        }
      }
    }
  }*/
}

#banner {
  width: 100%;
  height: 600px;
  position: relative;
  color: #FFF;
  background-color: $clr-dark;
  background-image: url(../img/banner-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: center;
  @media screen and (max-width : 767px) {
    height: 340px;
  }
  .overlay {
    // background-color: rgba(0, 0, 0, 0.6);
    background: linear-gradient(to bottom left,rgba(132,165,194,.8),rgba(31,37,43,.95) 50%);
    position: absolute;
  }
  .owl-stage-outer {
    height: 100%;
    .owl-stage {
      height: 100%;
      .owl-item {
        height: 100%;
        .owl-slide {
          height: 100%;
          position: relative;
          .caption {
            height: 35%;
            z-index: 45;
            margin: auto;
            padding-top: 0px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            .post-cat-label {
              font-family: $font-montserrat;
              font-size: 10px;
              font-weight: 700;
              color: #fff;
              text-transform: uppercase;
              white-space: nowrap;
              display: inline-flex;
              height: 20px;
              justify-content: center;
              align-items: center;
              border-radius: .25rem;
              box-shadow: 0 0 12px rgba(0,0,0,.3);
              background-color: $clr-yellow;
              margin-bottom: 1rem;
              padding: .25rem .75rem;
            }
            .post-meta {
              padding-bottom: 45px;
              .page-heading-title {
                text-transform: uppercase;
                color: #fff;
                letter-spacing: -0.02em;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 0;
                font-style: normal;
                line-height: 1em;
                margin-bottom: 0.475em;
                &:hover {
                  a {
                    color: #F0F0F0;
                  }
                }
              }
              @media (min-width: 992px) {
                .page-heading-title {
                  font-size: 42px;
                }
              }
              .post-meta-items {
                color: #fff;
                margin-bottom: 22px;
                li {
                  text-transform: uppercase;
                  line-height: 1.2em;
                  display: inline-block;
                  vertical-align: middle;
                  &.meta-date {
                    font-size: 10px;
                  }
                  @media (min-width: 992px) {
                    &.meta-date {
                      font-size: 12px;
                    }
                  }
                }
              }
              .post-author {
                text-align: center;
                .post-author-avatar {
                  display: block;
                  width: 40px;
                  height: 40px;
                  margin: 0 auto 10px auto;
                  overflow: hidden;
                  border-radius: 50%;
                  img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                  }
                }
                .post-author-info {
                  position: relative;
                  display: inline-block;
                  vertical-align: middle;
                  .post-author-name {
                    font-size: 13px;
                    font-weight: 700;
                    letter-spacing: -0.02em;
                    color: #fff;
                    margin: 0;
                    margin-bottom: .2em;
                  }
                }
              }
            }
          }
          &.jugador {
            text-align: left;
            background-color: #FFF;
            .content-wrapper {
              display: flex;
              position: relative;
              transform: translate3d(0, 0, 0);
              overflow: hidden;
              .img {
                position: relative;
                width: 400px;
                z-index: 0;
                display: inline-block;
                .shape {
                  display: block;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  right: 0px;
                  bottom: 0px;
                  background-color: $clr-primary;
                  z-index: -1;
                  &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    bottom: 0px;
                    right: 0px;
                    background-image: url(../img/banner-1.jpg);
                    background-size: cover;
                    opacity: 0.12;
                    background-position: center;
                    background-repeat: no-repeat;
                  }
                  &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: -180px;
                    width: 0px;
                    height: 0px;
                    border-bottom: 800px solid rgb(255, 255, 255);
                    border-left: 420px solid transparent;
                  }
                }
                .foto {
                  width: 300px;
                  height: 300px;
                  display: block;
                  background-size: cover;
                  background-position: center;
                  background-color: #eee;
                  border: 8px solid #FFF;
                  margin: 50px;
                  border-radius: 50%;
                }
              }
              .content {
                padding: 25px 45px 20px;
                .detalles {
                  position: relative;
                  padding: 50px 0px 0px;
                  margin-top: 40px;
                  .numero {
                    font-size: 42px;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: -0.02em;
                    line-height: 1em;
                    opacity: 0.2;
                    @media (min-width: 992px) {
                      display: block;
                      position: absolute;
                      top: 0px;
                      margin-left: -90px;
                      opacity: 0.08;
                      font-size: 110px;
                    }
                    @media (min-width: 1200px) {
                      font-size: 138px;
                    }
                  }
                  .info {
                    .nombre {
                      font-size: 24px;
                      font-style: normal;
                      font-weight: 700;
                      text-transform: uppercase;
                      margin-bottom: 0.75em;
                      @media (min-width: 992px) {
                        margin-bottom: 1.2em;
                      }
                      .nombres {
                        display: block;
                        font-size: 24px;
                        line-height: 1em;
                        @media (min-width: 1200px) {
                          font-size: 40px;
                        }
                      }
                      .apellidos {
                        display: block;
                        font-size: 40px;
                        line-height: 1em;
                        color: $clr-primary;
                        @media (min-width: 1200px) {
                          font-size: 70px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .owl-controls {
    .owl-dots {
      position: absolute;
      bottom: -10px;;
      left: 0;
      right: 0;
      text-align: center;
      .owl-dot {
         width: 10px;
         height: 10px;
         border-radius: 100%;
         border: 1px solid #FFF;
         background: transparent;
         margin-right: 5px;
         display: inline-block;  /*for making them horizontal, if you want vertical then you can use- display: block*/
         &.active {
           background: rgba(255, 255, 255, 0.5);
         }
      }
    }
  }
  a {
    color: #fff;
    &:hover {
      color: $clr-warning;
    }
    i {
      font-size: 24px;
      margin-left: 25px;
    }
  }
}
#equipos {
  #banner {
    width: 100%;
    height: 400px;
    margin: 0;
    color: $clr-carbon;
    background-image: none;
    background-color: #FFF;
  }
}
section#episodes {
  .section-header {
    background: #FFF;
    padding: 0 25px;
    border-left: 4px solid $clr-primary;
    border: 1px solid #e4e7ed;
    h2 {
      display: inline-block;
      margin: 24px 0;
      color: $clr-dark;
      font-size: 16px;
      font-weight: 800;
    }
  }
  #filters {
    float: right;
    margin-top: 25px;
    text-align: center;
    @media screen and (max-width : 767px) {
      display: none;
    }
    .btn {
      padding: 0;
      background-color: transparent;
      border: none;
      border-bottom: 3px solid transparent;
      width: auto;
      float: left;
      margin-left: 40px;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $clr-darkLT !important;
    }
  }

  .container-fluid.no-gutter {
  	padding: 0px;
  }
  .container-fluid.no-gutter .row [class*='col-']:not(:first-child),
  .container-fluid.no-gutter .row [class*='col-']:not(:last-child)
  {
    padding-right: 0;
    padding-left: 0;
  }

  .row {
     /*margin-left:0;
     margin-right:0;*/
  }

  .item {
    border: none;
  }

  /* Isotope Transitions
  ------------------------------- */
  .episode-container,
  .episode-container .episode {
    -webkit-transition-duration: 0.7s;
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    -ms-transition-duration: 0.7s;
    transition-duration: 0.7s;
  }
  .episode-container {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -o-transition-property: height, width;
    -ms-transition-property: height, width;
    transition-property: height, width;
    .episode {
      -webkit-transition-property: -webkit-transform, opacity;
      -moz-transition-property: -moz-transform, opacity;
      -o-transition-property: -o-transform, opacity;
      -ms-transition-property: -ms-transform, opacity;
      transition-property: transform, opacity;
      .overlay {
        background-color: rgba(0,0,0,0.95);
      }

      .post-item {
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        margin-top: 20px;
        .post-thumb {
          background-color: $clr-grey;
          float: none;
          margin: 0;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0);
          overflow: hidden;
          border-radius: 4px 4px 0 0;
          position: relative;
          z-index: 9;
          a {
            display:block;
            width: 100%;
            height: 250px;
            transition: transform 0.5s ease;
            backface-visibility: hidden;
            background-size: cover;
            background-position: center;
            &:hover {
              transform: scale(1.1);
            }
          }
          .post-cat {
            display: block;
            position: absolute;
            left: 23px;
            top: 23px;
            z-index: 999;
            .label {
              font-size: 10px;
              font-weight: 700;
              color: rgb(255, 255, 255);
              text-transform: uppercase;
              padding: 5px 10px;
              display: inline-block;
              border-radius: 0.25em;
              background-color: $clr-primary;
            }
          }
        }
        .post-inner {
          padding: 24px 24px;
          padding-top: 18px;
          position: relative;
          height: 150px;
          overflow: hidden;
          .post-date {
            display: inline-block;
            text-transform: uppercase;
            line-height: 1.2em;
            font-size: 10px;
            line-height: 1.2em;
          }
          .post-title {
            text-transform: uppercase;
            letter-spacing: -0.03em;
            font-style: normal;
            line-height: 1em;
            margin-bottom: 0.2em;
            font-size: 24px;
            font-weight: 700;
            & + .post-date {
              margin-top: 10px;
            }
            a {
              color: $clr-darkDK;
              transition: color 0.2s ease-in-out;
            }
          }
        }
        .post-footer {
          padding: 17px 24px;
          border-top: 1px solid #e4e7ed;
          display: table;
          width: 100%;
          height: 100%;
          .post-author {
            display: table-cell;
            width: 50%;
            text-align: left;
            .post-author-avatar {
              width: 24px;
              height: 24px;
              margin: 0 14px 0 0;
              display: inline-block;
              margin-right: 4px;
              vertical-align: middle;
              border-radius: 50%;
              overflow: hidden;
              img {
                max-width: 100%;
                height: auto;
                vertical-align: top;
              }
            }
            .post-author-info {
              position: relative;
              display: inline-block;
              vertical-align: middle;
              .post-author-name {
                margin-bottom: .2em;
                font-size: 10px;
                text-transform: uppercase;
                color: #9a9da2;
                font-weight: 400;
                font-style: normal;
              }
            }
          }
        }
      }
    }
  }
}
section#about {
  p {
    font-size: 24px;
    @media screen and (max-width : 767px) {
      font-size: 18px;
      text-align: center !important;
    }
  }
  .team {
    .profile {
      text-align: center;
      padding: 30px;
      margin-top: 50px;
      margin-bottom: 20px;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
        .imagen {
          animation: bounce .3s;
        }
      }
      .imagen {
        width: 100%;
        height: 120px;
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .nombre {
        //font-family: $font-poppins !important;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .puesto {
        font-size: 14px;
      }
      .social {
        width: 80%;
        display: inline-block;
        margin-top: 15px;
        text-align: center;
        li {
          width: 20%;
          height: 30px;
          line-height: 30px;
          display: block;
          float: left;
          a {
            font-size: 22px;
            &.disabled {
              &:hover {
                font-size: 22px;
              }
              i:before {
                color: $clr-greyLT !important;
              }
            }
            i {}
            &:hover {
              font-size: 26px;
            }
          }
        }
      }
    }
  }

  .chango {
    width: 262px;
    height: 540px;
  	display:block;
  	position:absolute;
  	left:-260px;
  	bottom:0;
  	background:transparent url('../img/chango.png') no-repeat;
  	background-size:contain;
  	z-index:9999;
  	-webkit-transition: all 1s ease-in-out;
  	-moz-transition: all 1s ease-in-out;
  	-o-transition: all 1s ease-in-out;
  	transition: all 1s ease-in-out;
  	&.konami {
  		left:0;
  		-webkit-transition: all 2s ease-in-out;
  		-moz-transition: all 2s ease-in-out;
  		-o-transition: all 2s ease-in-out;
  		transition: all 2s ease-in-out;
  	}
  }
}
@keyframes bounce {
	0%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
}
section#contact {
  background: $clr-darkDK; /* Old browsers */
  .section-header {
    p {
      color: #FFF;
      color: rgba(255, 255, 255, 0.75);
      font-size: 22px;
      padding: 0 20px;
    }
  }
  #CommentForm {
    .form-control {
      height: initial;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      padding: 2.5% 1.5%;
      border: 0;
      //font: 400 16px / 24px $font-roboto;
      color: rgba(45, 45, 45, 0.56);
      background-color: rgba(255, 255, 255, 0.95);
    }
    button, a {
      &:hover, &:focus {
        background-color: #FFF !important;
        border-color: #FFF !important;
        color: $clr-warningDK !important;
      }
    }
  }
}
section#donate {
  position: relative;
  color: #FFF;
  background-image: url(../img/brick-tile-bw.jpg);
  background-repeat: repeat;
  .overlay {
    background: rgba(70, 70, 70, 0.8);
    background: -moz-radial-gradient(center, ellipse cover, rgba(70, 70, 70, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(70, 70, 70, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(70, 70, 70, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
  }
  .section-header {
    h2 {
      color: $clr-warning;
    }
  }
  .form-wrapper {
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 30px;
    .form-header {
      h4 {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.85);
        text-transform: uppercase;
        //font-family: $font-poppins !important;
        font-weight: 600;
        font-size: 24px;
      }
      p {
        font-size: 15px;
      }
    }
    a {
      &:hover {
        background-color: $clr-warningDK;
        border-color: $clr-warningDK;
      }
    }
  }
}

footer {
  #copyright {
    padding: 92px 0 35px 0;
    margin: 0 auto;
    position: relative;
    text-align: center;
    img {
      width: 275px;
      position: relative;
      top: -53px;
    }
    a {
      color: rgba(255, 255, 255, 0.57);
      &:hover {
        color: $clr-warning;
      }
      i {
        margin-right: 25px;
        font-size: 20px;
        position: relative;
        top: -4px;
      }
    }
    nav {
      display: table;
      margin: 0 auto;
      .footer-menu {
        -webkit-font-smoothing: subpixel-antialiased;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          padding: 1px 0;
          float: left;
          border: 0;
          color: rgba(255, 255, 255, 0.57);
          //font: 600 11px $font-poppins;
          white-space: nowrap;
          a {
            margin: 0 12px;
            color: rgba(255, 255, 255, 0.76);
            text-transform: uppercase;
            &:hover {
              color: $clr-warning;
            }
          }
        }
      }
    }
    .copyright-text {
      padding-top: 5px;
      //font: 400 14px / 24px $font-roboto;
      color: rgba(255, 255, 255, 0.57);
    }
  }
}

.icon-rss:hover i      { color: #ff6600; }
.icon-facebook:hover i { color: #0084ff; }
.icon-twitter:hover i  { color: #55acee; }

}

@media only screen and (max-width : 991px) {
section {
  .container {
    .section-header {
      h2 {
        font-size: 24px;
      }
    }
  }
}
.geeksandbeers {
  header {
    #logo {
      img {
        width: 120px;
      }
    }
    nav {
      display: none;
    }
  }
  section#banner {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .owl-slide {
            .caption {
              height: 70%;
              & > .container {
                padding: 0 45px;
                .episode-info {
                  .season-title {
                    font-size: 14px;
                    i {
                      font-size: 15px;
                    }
                  }
                  .episode-title {
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .owl-controls {
      .owl-dots {
        margin: 0 auto; /*for centering the dots*/
        .owl-dot {
           width: 10px;
           height: 10px;
           border-radius: 100%;
           border: 1px solid #ccc;
           background: #333;
           margin-right: 5px;
           display: inline-block;  /*for making them horizontal, if you want vertical then you can use- display: block*/
        }
      }
    }
  }
  section#episodes {
    #filters {
      .btn {
        float: none;
        display: block;
      }
    }
  }
  footer {
    #copyright {
      padding: 92px 15px 35px;
      .copyright-text {
        font-size: 12px;
      }
    }
  }
  &.openVideo {
    section#banner {
      mediaelementwrapper {
        .fb-video {
          width: 80% !important;
          height: 100% !important;
          margin: 0 auto;
          & > span {
            top: calc(50% - 82px) !important;
            width: 80% !important;
            height: 50% !important;
            iframe {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}

}

html.transition {
  background-color: #fff;
  -webkit-transition: 250ms;
  -moz-transition: 250ms;
  transition: 250ms;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
}
body {
  -webkit-transition: 150ms;
  -moz-transition: 150ms;
  transition: 150ms;
  /*-webkit-perspective: 800px;
  -moz-perspective: 800px;
  perspective: 800px;*/
}
.flipper {
  -webkit-transition: 150ms;
  -moz-transition: 150ms;
  transition: 150ms;
  -webkit-transform-style: preserve-3d;
  -moz-transform: style preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flipper.keydown._38 {
  -webkit-transform: rotateX(10deg);
  -moz-transform: rotateX(10deg);
  transform: rotateX(10deg);
}
.flipper.keydown._40 {
  -webkit-transform: rotateX(-10deg);
  -moz-transform: rotateX(-10deg);
  transform: rotateX(-10deg);
}
.flipper.keydown._37 {
  -webkit-transform: rotateY(-10deg);
  -moz-transform: rotateY(-10deg);
  transform: rotateY(-10deg);
}
.flipper.keydown._39 {
  -webkit-transform: rotateY(10deg);
  -moz-transform: rotateY(10deg);
  transform: rotateY(10deg);
}
.flipper.keydown {
  -webkit-transform: translateZ(-50px);
  -moz-transform: translateZ(-50px);
  transform: translateZ(-50px);
}
